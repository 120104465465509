<template>
  <router-link
    class="inline-block w-28 my-2 mx-1 p-2 rounded-md hover:bg-white hover:text-black"
    :class="{
      'text-black': active,
      'bg-white': active,
      'text-white': !active
    }"
    :to="to"
    @click="onMenuTap"
  >
    {{ title }}
  </router-link>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useRoute } from 'vue-router'

export default {
  props: {
    to: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup (props, ctx) {
    const route = useRoute()

    const hash = computed(() => route.hash)

    const active = computed(() => hash.value === props.to)

    const onMenuTap = () => {
      ctx.emit('tap', props.to)
    }

    return {
      onMenuTap,
      active
    }
  }
}
</script>
