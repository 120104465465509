import { createStore } from 'vuex'
import Api from '../api'

const api = new Api()

export default createStore({
  state: {
    resume: null
  },
  getters: {
    profile (state) {
      return state.resume?.profile ?? null
    },
    fullName (state, getters) {
      if (!getters.profile) {
        return ''
      }

      return getters.profile.firstName + ' ' + getters.profile.lastName
    },
    certificates (state) {
      return state.resume?.certificates ?? []
    },
    contacts (state) {
      return state.resume?.contacts ?? []
    },
    educations (state) {
      return state.resume?.educations ?? []
    },
    projects (state) {
      return state.resume?.projects ?? []
    },
    skills (state) {
      return state.resume?.skills ?? []
    },
    works (state) {
      return state.resume?.works ?? []
    }
  },
  mutations: {
    mutResume (state, resume) {
      state.resume = resume
    }
  },
  actions: {
    async getResume ({ commit }) {
      const res = await api.getFull()
      if (res?.success) {
        commit('mutResume', res?.data)
      }

      return Promise.resolve(res)
    }
  },
  modules: {
  }
})
