<template>
  <svg xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg" id="svg8" version="1.1" viewBox="0 0 130.80983 15.223066" height="15.223066mm" width="130.80983mm">
    <!-- <defs id="defs2" />
    <metadata id="metadata5">
        <rdf:RDF>
          <cc:Work rdf:about="">
              <dc:format>image/svg+xml</dc:format>
              <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
              <dc:title></dc:title>
          </cc:Work>
        </rdf:RDF>
    </metadata> -->
    <g transform="translate(-64.651467,-87.780196)" id="layer1">
        <g id="text3715"
          class="splashscreen-wrapper"
          style="font-style:normal;font-weight:normal;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill-opacity:1" aria-label="ARIEFSN.DEV"
          fill="transparent"
          stroke="white"
          stroke-width="1"
        >
          <path id="path3717" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 65.3796,101.73326 q -0.220133,0 -0.474133,-0.1016 -0.254,-0.1016 -0.254,-0.32173 0,-0.32174 0.3556,-0.82974 0.3556,-0.524928 0.3556,-0.694262 0,-0.6096 0.372533,-0.8128 0.06773,-0.03387 0.2032,-0.389466 0.1524,-0.372534 0.3556,-0.846667 0.2032,-0.474133 0.4064,-0.694267 0.4572,-0.474133 1.744133,-3.522133 0.5588,-1.303866 1.557867,-3.132666 0.999066,-1.8288 1.303866,-2.099733 0.4064,-0.135467 0.491067,-0.135467 0.270933,0 0.5588,0.169333 0.287867,0.338667 1.016,2.032 0.745066,1.6764 0.745066,2.150533 0.01693,0.338667 0.321734,1.524 0.3048,1.1684 0.423333,1.862667 0.08467,0.643466 0.524933,2.167466 0.440267,1.524 0.440267,1.913467 0,0.186265 -0.08467,0.270935 -0.08467,0.0847 -0.372533,0.0847 -0.220133,0 -0.626533,-0.18626 -0.270934,-0.13547 -0.423334,-0.355602 -0.135466,-0.220133 -0.2032,-0.4572 -0.0508,-0.237066 -0.220133,-0.9144 -0.1524,-0.677333 -0.338667,-1.3208 l -0.321733,-1.100666 -0.728133,0.08467 q -1.0668,0.135466 -2.302933,0.220133 l -1.2192,0.08467 -0.491067,0.728133 q -1.3208,1.9304 -2.065866,3.708398 -0.389467,0.9144 -1.049867,0.9144 z m 7.078133,-7.399865 q 0,-0.321733 -0.3048,-1.6256 -0.287867,-1.3208 -0.508,-1.845733 -0.4572,0.643467 -0.931333,1.642533 -0.474134,0.999067 -0.728134,1.6764 l -0.254,0.677334 1.1176,-0.06773 q 1.049867,-0.06773 1.3208,-0.135466 0.287867,-0.06773 0.287867,-0.321734 z" />
          <path id="path3719" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 78.282799,90.455662 q 0.03387,-0.440266 0.829734,-1.337733 0.812799,-0.897467 1.117599,-0.897467 0.728134,0 3.081867,0.287867 2.370667,0.270933 2.827866,0.4064 1.286934,0.389467 1.286934,1.693333 0,0.778933 -0.4064,1.524 -0.4064,0.745067 -0.897467,1.286933 -0.491066,0.541867 -0.524933,0.6096 0,0.1016 -0.254,0.3048 -0.254,0.2032 -0.626533,0.440267 -0.3556,0.220133 -0.8128,0.474133 -0.440267,0.237067 -0.8128,0.423334 -0.3556,0.186266 -0.643467,0.338666 -0.270933,0.135467 -0.287867,0.135467 2.794,0.8636 3.6068,1.3716 0.694267,0.440266 0.846667,0.508 0.389466,0.06773 0.948266,0.6604 0.575734,0.592666 0.575734,0.9144 0,0.643468 -0.524934,0.643468 -0.321733,0 -0.982133,-0.321735 -0.6604,-0.321733 -1.761066,-0.880533 -1.083734,-0.5588 -2.099734,-0.931334 l -1.100666,-0.4064 0.0508,1.659467 q 0.03387,0.728135 0.03387,1.202265 0,0.32173 -0.03387,0.508 -0.03387,0.18627 -0.2032,0.3556 -0.169334,0.18627 -0.491067,0.18627 -0.491067,0 -0.677333,-0.59267 -0.186267,-0.59267 -0.186267,-1.693332 0,-2.048933 -0.5588,-2.286 -0.643466,-0.287866 -0.643466,-0.812799 0,-0.491067 0.626533,-0.795867 l 0.4064,-0.186267 -0.118534,-1.778 q -0.152399,-2.1844 -0.304799,-2.506133 -0.254,0.2032 -0.5588,0.2032 -0.3048,0 -0.524934,-0.2032 -0.2032,-0.2032 -0.2032,-0.508 z m 7.704667,0.3556 q -0.3556,-0.169333 -1.507067,-0.4064 -1.134533,-0.254 -2.150533,-0.254 h -1.049867 l 0.1016,0.6604 q 0.169333,1.253067 0.3556,3.9624 0.508,-0.169333 1.354667,-0.6096 0.8636,-0.440267 1.286933,-0.778933 0.440267,-0.3556 0.999067,-1.27 0.5588,-0.931334 0.6096,-1.303867 z" />
          <path id="path3721" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 96.757061,98.769928 q 0.270933,-0.1524 0.5588,-0.1524 0.3556,0 0.6096,0.2032 0.270933,0.2032 0.270933,0.524934 0,0.491066 -0.728133,0.863598 -0.7112,0.37253 -2.523067,0.89747 -1.1176,0.4064 -1.913467,0.4064 -0.1016,0 -0.3556,-0.0508 -0.254,-0.0339 -0.626533,-0.13547 -0.372533,-0.0847 -0.643466,-0.3048 -0.254,-0.22013 -0.254,-0.52493 0,-0.38947 0.2032,-0.524935 0.2032,-0.135467 0.677333,-0.135467 0.7112,0 1.693333,-0.3556 l 0.880533,-0.321733 -0.1524,-2.8956 q -0.1524,-3.048 -0.1524,-4.842933 v -1.4732 l -0.3048,0.0508 q -1.3716,0.237067 -1.998133,0.237067 -1.2192,0 -1.2192,-0.541867 0,-0.220133 0.2032,-0.389466 0.220134,-0.186267 0.643467,-0.338667 0.440266,-0.1524 0.8636,-0.270933 0.423333,-0.118534 1.0668,-0.287867 0.643466,-0.169333 1.083733,-0.3048 0.237067,-0.1016 1.6256,-0.1016 1.4224,0 1.710267,0.220133 0.321733,0.270934 0.321733,0.6604 0,0.4064 -0.372533,0.592667 -0.3556,0.186267 -1.2192,0.270933 l -0.321734,0.03387 0.03387,2.658533 q 0.08467,5.350933 0.338667,6.333066 z" />
          <path id="path3723" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 101.32905,91.082195 q 0,-0.999066 0.27094,-1.439333 0.27093,-0.4572 0.6096,-0.4572 0.13546,0 0.57573,-0.220133 0.4572,-0.220133 1.016,-0.474133 0.57573,-0.270934 1.5748,-0.491067 1.016,-0.220133 2.15053,-0.220133 1.778,0 1.778,0.677333 0,0.4572 -1.8288,0.728133 -1.4732,0.220134 -2.6924,0.6096 -1.2192,0.389467 -1.60866,0.728133 -0.22014,0.2032 -0.22014,0.541867 0,0.03387 0.0847,0.8636 0.0847,0.829733 0.18627,1.7272 0.1016,0.897467 0.13546,1.016 0.42334,-0.186267 2.11667,-0.541867 1.71027,-0.3556 2.35373,-0.3556 0.3556,0 0.52494,0.186267 0.16933,0.186267 0.16933,0.4064 0,0.270933 -0.2032,0.440267 -0.38947,0.372533 -2.4892,0.846666 -2.09973,0.4572 -2.30293,0.575734 -0.1016,0.06773 -0.1016,0.220133 0,1.236133 0.0339,2.032 l 0.0508,1.202266 h 0.7112 q 0.79587,0 2.032,-0.3048 1.23614,-0.3048 2.04894,-0.7112 0.57573,-0.321733 0.79586,-0.321733 0.28787,0 0.508,0.270933 0.23707,0.270934 0.23707,0.524934 0,0.524933 -1.03293,1.066798 -1.016,0.54187 -2.42147,0.8636 -1.38853,0.32173 -2.52307,0.32173 -1.33773,0 -1.6256,-0.49106 -0.18626,-0.32174 -0.28786,-1.439335 -0.1016,-1.1176 -0.23707,-3.217333 -0.11853,-2.116667 -0.27093,-3.640667 -0.11854,-1.4224 -0.11854,-1.524 z" />
          <path id="path3725" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 115.2313,99.650462 q 0,0.846668 -0.13547,1.117598 -0.11853,0.27093 -0.5588,0.27093 -0.6604,0 -0.84667,-0.33866 -0.1016,-0.28787 -0.1016,-1.117602 0,-0.778933 -0.3048,-3.014133 -0.28786,-2.2352 -0.28786,-4.572 v -2.810933 l 1.40546,-0.491066 q 1.40547,-0.440267 2.26907,-0.440267 0.5588,0 1.13453,-0.2032 0.57574,-0.2032 1.55787,-0.2032 1.50707,0 1.50707,0.541867 0,0.186266 -0.0339,0.321733 -0.0169,0.118533 -0.11853,0.220133 -0.0847,0.1016 -0.2032,0.186267 -0.1016,0.06773 -0.32174,0.135467 -0.22013,0.06773 -0.44026,0.118533 -0.22014,0.0508 -0.6096,0.118533 -0.37254,0.06773 -0.74507,0.135467 -0.3556,0.0508 -0.93133,0.169333 -0.57574,0.1016 -1.1176,0.2032 l -1.69334,0.321733 v 0.9652 q 0,1.388534 0.11854,2.048934 l 0.1016,0.541866 0.9652,-0.1016 q 1.13453,-0.135466 2.25213,-0.135466 1.55787,0 1.55787,0.474133 0,0.270933 -0.4572,0.524933 -0.44027,0.237067 -1.10067,0.4064 -0.64347,0.1524 -1.04987,0.237067 -0.4064,0.08467 -0.62653,0.118533 l -1.38853,0.220134 0.1524,2.489199 q 0.0508,1.27 0.0508,1.540934 z" />
          <path id="path3727" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 124.79862,97.127395 q 0.6604,0 0.6604,0.7112 0,0.338667 -0.28787,0.643467 -0.27093,0.287866 -0.27093,0.3556 0,0.118533 0.11853,0.3556 0.13547,0.287866 0.3556,0.3556 0.23707,0.0508 1.30387,0.0508 0.9144,0 1.35466,-0.08467 0.44027,-0.1016 0.8636,-0.372533 0.6604,-0.440267 0.8128,-0.677334 0.1524,-0.237066 0.1524,-0.8636 0,-0.440266 -0.16933,-0.592666 -0.1524,-0.1524 -0.98213,-0.508 -1.0668,-0.474133 -1.54094,-0.524933 -0.89746,0 -2.54,-1.3208 -1.6256,-1.337734 -1.6256,-2.065867 0,-0.999067 0.99907,-2.201333 0.99907,-1.2192 2.2352,-1.761067 0.69427,-0.3048 2.4892,-0.3048 0.79587,0 1.20227,0.118534 0.4064,0.118533 0.84666,0.474133 0.8128,0.677333 0.8128,1.202266 0,0.626534 -0.3556,0.626534 -0.18626,0 -0.42333,-0.169334 -0.23707,-0.169333 -0.47413,-0.3556 -0.23707,-0.203199 -0.67734,-0.372533 -0.44026,-0.169333 -0.98213,-0.169333 -1.43933,0 -2.65853,0.9652 -1.20227,0.9652 -1.20227,2.0828 0,0.372533 0.99907,0.897466 1.016,0.524934 2.62466,0.999067 3.01414,0.897467 3.01414,2.8956 0,0.440266 -0.1524,0.931333 -0.42334,1.405467 -1.45627,1.947335 -1.016,0.54186 -3.58987,0.69426 l -1.8288,0.11854 -0.52493,-1.08374 q -0.37253,-0.728128 -0.37253,-1.490128 0,-0.677334 0.33866,-1.083734 0.3556,-0.423333 0.93134,-0.423333 z" />
          <path id="path3729" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 135.97459,95.857395 q 0,0.8128 0.0677,1.4224 0.0677,0.6096 0.0677,0.795867 0,1.016 -0.27093,1.811866 -0.27093,0.795862 -0.67733,0.795862 -0.7112,0 -0.9144,-0.508 -0.2032,-0.507995 -0.2032,-2.252128 0,-1.964267 0.1016,-5.503333 0.11853,-3.556 0.23706,-3.945467 0.0169,-0.06773 0.0677,-0.1524 0.0508,-0.1016 0.28786,-0.254 0.23707,-0.169333 0.5588,-0.169333 0.33867,0 0.69427,0.220133 0.3048,0.220134 1.40547,1.6764 1.1176,1.439333 1.1176,1.6764 0.0339,0.08467 0.38946,0.592667 0.3556,0.508 1.10067,1.693333 0.762,1.1684 1.5748,2.6416 1.65947,3.014133 2.21827,3.234266 0.254,-0.4064 0.508,-4.030133 0.3048,-4.487333 0.49106,-5.334 0.2032,-0.982133 0.72814,-1.134533 0.1524,-0.0508 0.3556,-0.0508 0.762,0 0.762,1.1684 0,0.389467 -0.0847,1.185333 -0.0847,0.778934 -0.27093,2.4384 -0.16934,1.642534 -0.32174,3.5052 -0.0677,0.931333 -0.11853,1.4224 -0.0508,0.474133 -0.13547,1.049867 -0.0847,0.558798 -0.16933,0.812798 -0.0847,0.254 -0.23707,0.508 -0.1524,0.23707 -0.3556,0.3048 -0.18626,0.0847 -0.47413,0.0847 -1.27,0 -2.25213,-0.88054 -0.9652,-0.897462 -1.96427,-2.777062 -0.47413,-0.880533 -0.84667,-1.507066 -0.37253,-0.643467 -0.508,-0.829733 -0.13546,-0.186267 -0.23706,-0.389467 -0.0847,-0.220133 -0.16934,-0.541867 -0.0847,-0.3048 -0.4572,-0.897466 -0.37253,-0.592667 -1.016,-1.4732 -0.62653,-0.880534 -0.72813,-1.032934 -0.32173,1.5748 -0.32173,4.6228 z" />
          <path id="path3731" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 149.67363,100.93739 q 0.0339,-0.74506 0.93133,-1.676395 0.89747,-0.931333 1.28694,-0.931333 0.1016,0 0.11853,-0.01693 0.0339,-0.03387 0.0508,-0.118533 0.0169,-0.08467 0.0677,-0.169333 0.18627,-0.237067 0.54187,-0.237067 0.23707,0 0.42333,0.135467 0.18627,0.118533 0.2032,0.321733 0.0847,0.08467 0.27094,0.08467 0.54186,0 0.89746,0.491066 0.3556,0.491067 0.3556,1.151467 0,1.015995 -0.94826,2.015065 -0.93134,1.016 -2.1336,1.016 -0.54187,0 -0.98214,-0.23707 -0.42333,-0.23706 -0.64346,-0.59266 -0.22014,-0.33867 -0.33867,-0.6604 -0.1016,-0.32174 -0.1016,-0.57574 z m 2.3876,-0.1524 h 0.0169 q 0.23707,0 0.54187,-0.3048 -0.508,0.0508 -0.5588,0.3048 z" />
          <path id="path3733" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 162.9324,89.608996 q -0.42333,0 -0.52493,0.08467 -0.1016,0.08467 -0.1016,0.440267 0,0.541866 0.47413,3.979333 0.49107,3.420533 0.49107,4.487333 0,0.372533 -0.0339,0.7112 l -0.1016,0.6096 h 0.69427 q 1.50707,0 3.5052,-2.065867 1.13453,-1.1684 1.524,-2.370666 0.38947,-1.202267 0.38947,-2.9464 0,-0.694267 -0.1016,-0.948267 -0.1016,-0.254 -0.49107,-0.6604 -0.4064,-0.4064 -0.89747,-0.592666 -0.47413,-0.186267 -1.60866,-0.389467 -2.15054,-0.338666 -3.21734,-0.338666 z m -5.14773,0.389466 q 0.0339,-0.4064 0.3048,-0.745066 0.28787,-0.3556 0.54187,-0.3556 0.16933,-0.0508 0.32173,-0.287867 0.32173,-0.491067 1.8288,-0.643467 1.778,-0.169333 2.4384,-0.169333 0.77893,0 1.89653,0.186267 3.2004,0.508 4.48734,1.524 1.30386,0.999066 1.30386,3.031066 0,1.761067 -0.64346,3.335867 -0.64347,1.574799 -1.65947,2.641599 -1.016,1.0668 -2.16747,1.761062 -1.13453,0.69427 -2.2352,0.93134 -0.37253,0.1016 -0.5588,0.27093 -0.18626,0.18627 -0.2032,0.3556 -0.0169,0.16933 -0.13546,0.33867 -0.11854,0.18626 -0.37254,0.27093 -0.16933,0.0508 -0.3556,0.0508 -0.3048,0 -0.52493,-0.11853 -0.2032,-0.1016 -0.22013,-0.28787 l -0.254,-0.1016 q -0.27094,-0.11853 -0.44027,-0.33867 -0.16933,-0.2032 -0.16933,-0.5588 0,-0.59266 0.4572,-0.762 0.18626,-0.13546 0.18626,-1.202262 0,-0.6604 -0.0847,-1.354666 -0.0847,-0.694267 -0.28787,-2.099733 -0.18627,-1.4224 -0.3556,-3.1496 l -0.254,-2.624667 -0.38947,0.03387 q -0.52493,0.06773 -0.99906,0.508 -0.47414,0.423333 -0.67734,0.423333 -0.22013,0 -0.508,-0.287867 -0.27093,-0.3048 -0.27093,-0.575733 z" />
          <path id="path3735" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 173.27866,91.082195 q 0,-0.999066 0.27094,-1.439333 0.27093,-0.4572 0.6096,-0.4572 0.13546,0 0.57573,-0.220133 0.4572,-0.220133 1.016,-0.474133 0.57573,-0.270934 1.5748,-0.491067 1.016,-0.220133 2.15053,-0.220133 1.778,0 1.778,0.677333 0,0.4572 -1.8288,0.728133 -1.4732,0.220134 -2.6924,0.6096 -1.2192,0.389467 -1.60866,0.728133 -0.22014,0.2032 -0.22014,0.541867 0,0.03387 0.0847,0.8636 0.0847,0.829733 0.18627,1.7272 0.1016,0.897467 0.13546,1.016 0.42334,-0.186267 2.11667,-0.541867 1.71027,-0.3556 2.35373,-0.3556 0.3556,0 0.52494,0.186267 0.16933,0.186267 0.16933,0.4064 0,0.270933 -0.2032,0.440267 -0.38947,0.372533 -2.4892,0.846666 -2.09973,0.4572 -2.30293,0.575734 -0.1016,0.06773 -0.1016,0.220133 0,1.236133 0.0339,2.032 l 0.0508,1.202266 h 0.7112 q 0.79587,0 2.032,-0.3048 1.23614,-0.3048 2.04894,-0.7112 0.57573,-0.321733 0.79586,-0.321733 0.28787,0 0.508,0.270933 0.23707,0.270934 0.23707,0.524934 0,0.524933 -1.03293,1.066798 -1.016,0.54187 -2.42147,0.8636 -1.38853,0.32173 -2.52307,0.32173 -1.33773,0 -1.6256,-0.49106 -0.18626,-0.32174 -0.28786,-1.439335 -0.1016,-1.1176 -0.23707,-3.217333 -0.11853,-2.116667 -0.27093,-3.640667 -0.11854,-1.4224 -0.11854,-1.524 z" />
          <path id="path3737" class="asn-path" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Purisa;-inkscape-font-specification:'Purisa, Bold';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start" d="m 183.86197,89.016329 q 0.0339,-0.423333 0.33867,-0.626533 0.3048,-0.220134 0.57573,-0.220134 0.28787,0 0.47413,0.237067 0.2032,0.237067 0.32174,0.524933 0.13546,0.287867 0.22013,0.338667 0.38947,0 0.38947,0.338667 0,0.169333 0.22013,0.694266 0.23707,0.524933 0.762,1.761067 0.52493,1.2192 1.03293,2.607733 1.15147,3.081866 1.64254,3.877733 0.33866,-0.592667 1.016,-2.167466 0.67733,-1.591734 0.9652,-2.523067 0.47413,-1.439333 1.25306,-3.285067 0.77894,-1.845733 1.28694,-2.150533 l 0.0677,-0.01693 q 0.0677,-0.01693 0.13547,-0.01693 0.27093,0 0.57573,0.1524 0.32173,0.135466 0.32173,0.474133 0,0.389467 -0.28786,1.2192 -0.27094,0.8128 -0.84667,2.286 -0.5588,1.4732 -0.94827,2.624666 -0.42333,1.27 -1.6764,3.725333 -0.6604,1.270002 -1.03293,1.761062 -0.37253,0.49107 -0.8128,0.49107 -0.7112,0 -1.1176,-0.52493 -0.38947,-0.54187 -1.1684,-2.489202 l -1.8796,-4.673599 q -0.9144,-2.286 -0.9144,-2.607734 -0.0169,0.01693 -0.0169,0.03387 -0.0169,-0.03387 -0.4572,-0.7112 -0.44027,-0.677333 -0.44027,-1.134533 z" />
        </g>
    </g>
  </svg>
</template>
