<template>
  <div v-if="ready">
    <header class="sticky top-0 z-50 text-center">
      <nav-menu />
    </header>
    <main class="relative">
      <router-view class="px-3" />
    </main>
    <footer class="bg-dark flex justify-center">
      <contacts-content />
      <scroll-to-top />
    </footer>
  </div>
  <splash-screen v-else @on-loop-complete="onLoopComplete" />
</template>

<script>
import NavMenu from './components/NavMenu.vue'
import ContactsContent from './components/contents/ContactsContent.vue'
import ScrollToTop from './components/ScrollToTop.vue'
import { watch, computed, onMounted, ref } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { capitalize } from '@/utils'
import { useStore } from 'vuex'
import SplashScreen from './components/SplashScreen.vue'

export default {
  components: {
    NavMenu,
    ContactsContent,
    ScrollToTop,
    SplashScreen
  },
  setup () {
    const route = useRoute()
    const store = useStore()

    const resume = computed(() => store.state.resume)

    const ready = ref(false)

    onMounted(async () => {
      await store.dispatch('getResume')
    })

    watch(route, (val) => {
      let title = 'Me'
      const hash = val.hash

      if (hash) {
        const id = hash.replace('#', '')

        if (id !== 'me') {
          title += ` - ${capitalize(id)}`
        }
      }

      document.title = title
    }, {
      immediate: true,
      deep: true
    })

    const onLoopComplete = (anime) => {
      if (resume.value?.profile) {
        ready.value = true
      }
    }

    return {
      resume,
      onLoopComplete,
      ready
    }
  }
}
</script>
