import axios from 'axios'

class Api {
  ajax = null

  constructor () {
    if (!this.ajax) {
      this.ajax = axios.create({
        baseURL: process.env.VUE_APP_API_URL
      })
    }
  }

  async getFull () {
    try {
      const { data, statusText } = await this.ajax.get('/user/ariefsn')

      if (data.success) {
        return Promise.resolve(data)
      }

      return Promise.resolve({
        success: false,
        data: null,
        message: data?.message ?? statusText
      })
    } catch (error) {
      return Promise.resolve({
        success: false,
        data: null,
        message: error?.response?.data?.message ?? error?.message ?? error?.statusText
      })
    }
  }

  async getByField (field) {
    if (!field) {
      return Promise.resolve({
        success: false,
        data: null,
        message: 'field can\t be null'
      })
    }

    try {
      const res = await this.ajax.get('/user/ariefsn/' + field)

      return Promise.resolve(res)
    } catch (error) {
      return Promise.resolve({
        success: false,
        data: null,
        message: error?.response?.data?.message ?? error?.message ?? error?.statusText
      })
    }
  }
}

export default Api
