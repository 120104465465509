<template>
  <div class="bg-dark h-screen w-screen flex justify-center items-center">
    <logo-asn class="w-8/12 md:w-auto" />
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import LogoAsn from './icons/LogoAsn.vue'
import anime from 'animejs'

export default {
  components: {
    LogoAsn
  },
  setup (props, ctx) {
    onMounted(() => {
      // eslint-disable-next-line no-unused-vars
      const lineDrawing = anime({
        targets: '.splashscreen-wrapper path',
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 3000,
        delay: function (el, i) { return i * 250 },
        direction: 'alternate',
        loop: true,
        loopComplete: function (anim) {
          ctx.emit('onLoopComplete', anim)
        }
      })
    })

    return {
    }
  }
}
</script>
