<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="300.000000pt"
    height="300.000000pt"
    viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet"
    :style="{
      backgroundColor: bgColor,
      width: width,
      height: height
    }"
  >
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
    :fill="fillColor" stroke="none">
      <path class="dicoding-a" d="M0 1500 l0 -1500 1500 0 1500 0 0 1500 0 1500 -1500 0 -1500 0 0
      -1500z m1694 1295 c317 -67 581 -296 711 -617 53 -131 55 -161 47 -818 l-7
      -605 -33 -67 c-81 -167 -266 -344 -455 -437 -180 -87 -269 -105 -504 -98 -190
      5 -244 16 -383 80 -192 88 -413 301 -485 467 -25 55 -25 143 -2 187 32 60 140
      104 220 89 51 -10 110 -67 146 -141 57 -117 201 -236 346 -285 85 -28 250 -39
      334 -20 158 33 283 111 370 229 69 94 71 100 71 205 l0 96 -63 -44 c-35 -23
      -95 -54 -133 -68 -38 -14 -85 -31 -104 -38 -22 -8 -109 -15 -231 -17 -178 -5
      -204 -3 -280 17 -172 44 -301 122 -454 274 -87 87 -108 115 -152 201 -173 338
      -156 707 45 1000 53 76 170 195 247 250 68 49 185 110 230 121 17 4 35 10 40
      13 62 38 349 54 479 26z"/>
      <path class="dicoding-b" d="M1375 2416 c-180 -35 -361 -202 -429 -395 -25 -71 -31 -242 -11 -319
      43 -164 139 -279 311 -374 74 -40 150 -57 258 -58 80 0 136 9 191 30 165 63
      319 228 361 385 8 29 14 101 14 170 0 100 -4 131 -24 185 -29 83 -81 164 -140
      218 -53 49 -178 132 -198 132 -8 0 -18 4 -23 9 -12 10 -165 31 -215 30 -19 -1
      -62 -6 -95 -13z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: 'white'
    },
    bgColor: {
      type: String,
      default: 'transparent'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  }
}
</script>
