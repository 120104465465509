import moment from 'moment'

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function formatDate (date, format) {
  if (!date) {
    return ''
  }

  return moment(date).format(format)
}

export function capitalize (text) {
  if (!text) {
    return ''
  }

  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function updateHash (hash) {
  const id = hash.replace('#', '')
  const el = document.getElementById(id)

  window.addEventListener('scroll', () => {
    const offsetTop = el.offsetTop
    const height = el.scrollHeight
    const offsetY = window.pageYOffset + 10

    if ((offsetTop < offsetY) && (offsetTop + height > offsetY)) {
      // window.location.hash = hash
    }
  })
}

export async function scrollToElement (elementId, { isFirst }) {
  if (!elementId) {
    return
  }

  const id = elementId.replace('#', '')
  const el = document.getElementById(id)

  if (el) {
    const offsetTop = el.offsetTop

    if (isFirst) {
      await sleep(800)
      isFirst = false
    }

    scroll({
      top: offsetTop,
      behavior: 'smooth'
    })
  }
}

export function isInViewport (id) {
  const element = document.getElementById(id)

  if (!element) {
    return false
  }

  const rect = element.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
