<template>
  <div id="contacts" class="container w-full py-12 text-white px-3 md:px-0">
    <div class="text-5xl">Ping Me</div>
    <div class="mt-5 flex justify-center flex-wrap">
      <a
        v-for="(c, i) in contacts"
        :key="'contact-' + i"
        :href="getLink(c)"
        target="_blank"
        class="m-1 flex hover:bg-white hover:text-black rounded-md"
        :title="c.name"
        @mouseenter="onMouseEnter(c)"
        @mouseleave="onMouseLeave(c)"
      >
        <logo-dicoding v-if="c.name === 'Dicoding'" class="m-2 text-3xl" :fillColor="otherContacts.dicoding.fillColor" :bgColor="otherContacts.dicoding.bgColor" />
        <logo-upwork v-else-if="c.name === 'Upwork'" class="m-2 text-3xl" :fillColor="otherContacts.upwork.fillColor" :bgColor="otherContacts.upwork.bgColor" />
        <fa-icon
          v-else
          class="self-center m-2 text-3xl"
          :icon="getIcon(c)"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import LogoDicoding from '../icons/LogoDicoding.vue'
import LogoUpwork from '../icons/LogoUpwork.vue'
import { updateHash } from '@/utils'

export default {
  components: { LogoDicoding, LogoUpwork },
  setup () {
    const store = useStore()
    const otherContacts = ref({
      dicoding: {
        hover: false,
        bgColor: 'transparent',
        fillColor: 'white'
      },
      upwork: {
        hover: false,
        bgColor: 'transparent',
        fillColor: 'white'
      }
    })

    const contacts = computed(() => store.getters.contacts ?? [])

    const getIcon = (contact) => {
      const unknown = ['dicoding', 'upwork', 'website']
      const name = contact?.name?.toLowerCase()

      if (name === 'website') {
        return ['fas', 'globe']
      }

      if (name === 'email') {
        return ['fas', 'envelope']
      }

      if (name === 'phone') {
        return ['fas', name]
      }

      if (unknown.includes(name)) {
        return ['fas', 'link']
      }

      return ['fab', name]
    }

    const getLink = (contact) => {
      const val = contact?.value

      switch (contact?.type) {
        case 'link':
          return val
        case 'email':
          return `mailto:${val}`
        case 'phone':
          return `tel:${val}`
        case 'skype':
          return `skype:${val}?chat`

        default:
          return val
      }
    }

    const onMouseEnter = (contact) => {
      const unknown = ['dicoding', 'upwork']
      const name = contact?.name?.toLowerCase()
      const index = unknown.indexOf(name)
      if (index > -1) {
        const found = unknown[index]

        otherContacts.value[found].hover = true
        otherContacts.value[found].fillColor = 'black'
        otherContacts.value[found].bgColor = 'white'
      }
    }

    const onMouseLeave = (contact) => {
      for (const key in otherContacts.value) {
        otherContacts.value[key].hover = false
        otherContacts.value[key].fillColor = 'white'
        otherContacts.value[key].bgColor = 'transparent'
      }
    }

    onMounted(() => {
      updateHash('#contacts')
    })

    return {
      contacts,
      getIcon,
      getLink,
      otherContacts,
      onMouseEnter,
      onMouseLeave
    }
  }
}
</script>
