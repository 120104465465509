<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="348.000000pt"
    height="348.000000pt"
    viewBox="0 0 348.000000 348.000000"
    preserveAspectRatio="xMidYMid meet"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    :style="{
      backgroundColor: bgColor,
      width: width,
      height: height
    }"
  >

    <g class="logo-upwork" transform="translate(0.000000,348.000000) scale(0.100000,-0.100000)"
    :fill="fillColor" stroke="none">
      <path d="M1590 3244 c-159 -29 -210 -41 -299 -71 -423 -140 -776 -476 -936
      -891 -223 -579 -77 -1225 373 -1653 213 -203 470 -336 767 -396 136 -27 443
      -25 582 5 438 94 821 376 1029 757 175 322 230 688 158 1044 -91 447 -398 846
      -809 1051 -230 114 -385 151 -655 155 -102 2 -196 1 -210 -1z m-550 -1265 l5
      -346 27 -41 c52 -77 157 -117 248 -92 58 16 133 89 149 145 7 25 11 158 11
      363 l0 323 116 -3 116 -3 33 -90 c40 -112 99 -239 155 -335 41 -70 44 -73 53
      -50 41 108 81 172 139 226 74 68 137 99 234 115 220 36 452 -116 510 -335 30
      -114 8 -258 -55 -358 -97 -153 -231 -229 -401 -229 -99 0 -159 12 -249 53 -35
      15 -66 26 -68 24 -4 -4 -22 -88 -99 -448 l-5 -27 -120 -3 c-65 -2 -119 -2
      -119 -1 0 2 32 148 70 324 39 177 70 330 70 341 0 10 -18 44 -39 76 -22 31
      -57 91 -80 132 l-41 75 0 -72 c0 -184 -72 -323 -212 -414 -173 -111 -407 -84
      -553 65 -29 30 -65 79 -79 108 -48 99 -56 165 -56 504 0 171 3 314 7 318 4 3
      57 5 117 4 l111 -3 5 -346z"/>
      <path d="M2295 1951 c-46 -20 -101 -79 -121 -128 -8 -18 -24 -75 -35 -125
      l-21 -91 23 -19 c72 -56 197 -93 271 -82 52 9 125 58 161 111 41 57 49 160 19
      219 -57 113 -190 164 -297 115z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: 'white'
    },
    bgColor: {
      type: String,
      default: 'transparent'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  }
}
</script>
