import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './styles/index.css'
import VueSplide from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSkype, faWhatsapp, faYoutube, faTwitter, faFacebook, faLinkedin, faGithub, faGit, faGitlab, faBitbucket } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope, faLink, faStar, faGlobe, faMapMarkerAlt, faExternalLinkSquareAlt, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faBuilding, faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import aos from 'aos'
import 'aos/dist/aos.css'

library.add(faSkype, faWhatsapp, faYoutube, faTwitter, faFacebook, faLinkedin, faGithub, faGit, faGitlab, faBitbucket, faPhone, faEnvelope, faLink, faStar, faGlobe, faMapMarkerAlt, faExternalLinkSquareAlt, faChevronCircleUp, faBuilding, faStarEmpty)

const app = createApp(App)
app.component('fa-icon', FontAwesomeIcon)
app.use(VueSplide)
app.use(store).use(router)
app.mount('#app')
aos.init({
  once: true
})

// document.body.onmousemove = function (e) {
//   document.documentElement.style.setProperty('--x', (e.clientX) + 'px')
//   document.documentElement.style.setProperty('--y', (e.clientY) + 'px')
// }
