<template>
  <fa-icon
    v-if="show"
    icon="chevron-circle-up"
    class="text-white text-5xl fixed right-8 bottom-8 cursor-pointer"
    @click="toTop"
  />
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()

    const show = ref(false)

    const toTop = () => {
      if (route.hash && route.hash !== '#me') {
        router.push('#me')
      }
      // document.body.scrollTop = 0
      // document.documentElement.scrollTop = 0
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    onMounted(() => {
      window.onscroll = () => {
        const offset = 25

        if (document.body.scrollTop > offset || document.documentElement.scrollTop > offset) {
          show.value = true
        } else {
          show.value = false
        }
      }
    })

    return {
      show,
      toTop
    }
  }
}
</script>
