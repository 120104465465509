<template>
  <div class="bg-dark shadow-xl">
    <nav-menu-item v-for="(m, i) in menuItems" :key="'menu-' + i" :title="m.title" :to="m.to" @tap="onMenuTap" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { sleep, scrollToElement } from '@/utils'
import NavMenuItem from './NavMenuItem.vue'

export default {
  components: { NavMenuItem },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const menuItems = ref([])
    const isFirst = ref(true)

    menuItems.value = [{
      title: 'Me',
      to: '#me'
    }, {
      title: 'Experiences',
      to: '#experiences'
    }, {
      title: 'Projects',
      to: '#projects'
    }, {
      title: 'Certificates',
      to: '#certificates'
    }, {
      title: 'Contacts',
      to: '#contacts'
    }]

    const target = ref('')

    // watch(target, async (val) => {
    //   scrollToElement(val)
    // })

    const onMenuTap = (menu) => {
      // target.value = menu
      scrollToElement(menu, { isFirst: isFirst.value })
    }

    onMounted(async () => {
      await sleep(100)
      if (route.hash !== '#me') {
        target.value = '#me'
        router.push('#me')
        scrollToElement(route.hash, { isFirst: isFirst.value })
      }
    })

    return {
      menuItems,
      onMenuTap
    }
  }
}
</script>
